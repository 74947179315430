import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Contact" />
    <h1 className="title has-text-weight-bold">Contact Us</h1>
    <p className="mb-4 is-family-secondary">Talk to us about large orders of wood boxes.</p>
  </Layout>
)

export default IndexPage
